import $ from 'jquery';
import window from './window';
import type {
  Product,
  ResponseData,
  ProductAddition,
  ReconstructedProductAdditions,
  ProductSku
} from './@types/Product.d';

export default () => {

  window.addEventListener('DOMContentLoaded', () => {

    document.addEventListener('click', e => {
      if (e.target) {
        const eventTarget = e.target as HTMLElement;
        if (eventTarget.classList.contains('l-cartinQuantity__button--minus')) {
          const cartinQuantityForm = eventTarget.closest('.l-cartinQuantity__form');
          const quantityInput: HTMLInputElement = cartinQuantityForm.querySelector('.l-cartinQuantity__input');
          const quantity = Number(quantityInput.value);
          const additionOptions: NodeListOf<HTMLOptionElement> = eventTarget.closest('form').querySelectorAll('[name^="addition_quantity["] > option');
          if (quantity > 1) {
            quantityInput.value = String(quantity - 1);
            additionOptions.forEach(additionOption => {
              additionOption.value = String(quantityInput.value);
            });
          }
        } else if (eventTarget.classList.contains('l-cartinQuantity__button--plus')) {
          const cartinQuantityForm = eventTarget.closest('.l-cartinQuantity__form');
          const quantityInput: HTMLInputElement = cartinQuantityForm.querySelector('.l-cartinQuantity__input');
          const quantity = Number(quantityInput.value);
          const quantityMax = quantityInput.max ? Number(quantityInput.max) : 10000;
          const additionOptions: NodeListOf<HTMLOptionElement> = eventTarget.closest('form').querySelectorAll('[name^="addition_quantity["] > option');
          if (quantity < quantityMax) {
            quantityInput.value =  String(quantity + 1);
            additionOptions.forEach(additionOption => {
              additionOption.value = String(quantityInput.value);
            });
          }
        }
      }
    });

  });

  $(() => {

    const $triggersAddToCart = $('.js-addToCart');

    const LC_PATH = {
      product: '/product/',
      cartin: '/ec/cart/add/'
    }    


    const template = {
      quantity: `
        <div class="l-cartinQuantity__form">   
          <div class="selectBox selectBox_01">
            <label class="selectBox__lb">{regular_interval_label}</label>
            <div class="selectBox__outer">
              <select class="selectBox__opt cartinQuantity__input1" name="regular_int">
                {regularIntervalOptions}
              </select>
            </div>
          </div>
          <div class="qty_and_cart">
            <div class="selectBox">
              <label class="selectBox__lb">{quantity}</label>            
              <div class="selectBox__outer">
                <select class="l-cartinQuantity__input selectBox__opt" name="quantity">      
                  <script>
                  </script>
                </div>
                <p class="productActoins">
                <input type="hidden" name="product_sku_id" value="{product_sku_id}">               
              </div></div>`,
      skuSection: `
        <div class="l-cartinSku__section"> 
          <dt class="l-cartinSku__title">{title}</dt>
          <dd class="l-cartinSku__list">{items}</dd>
        </div>`,
      skuItemWithImage: `
        <label class="l-cartinSku__item l-cartinSku__item--image">
          <input class="l-cartinSku__radio" type="radio" name="{sku_key}" data-name="{name}">
          <figure class="l-cartinSku__figure hasImg is-loaded"> 
          <img src="{image}" alt="">
          <p class="l-cartinSku__text">{name}</p>
          </figure>
          
        </label>`,
      skuItemWithoutImage: `
        <label class="l-cartinSku__item">
          <input class="l-cartinSku__radio" type="radio" name="{sku_key}" data-name="{name}">
          <p class="l-cartinSku__button">{name}</p>
        </label>`,
      optionItem: `
        <div class="l-cartinOption__item{item_class}"> 
          <dt class="l-cartinOption__label">{name}</dt>
          <dd class="l-cartinOption__form"> 
            <select class="l-cartinOption__select" name="">
              <option value="">{no_selected}</option>
              {options}
            </select>
          </dd>
          {note}
        </div>`,
      optionItemNote: `
        <dd class="l-cartinOption__note">{note}</dd>`,
      loading: `
        <div class="ball-scale-ripple"><div></div></div>`,
      product_info: `
        <header class="productMainSection__header">
          <h1 class="productMainSection__title">{product_name}</h1>
        </header>
        <div class="parent_price">
          <div class="productMainSection__info">
            <p class="productMainSection__price color_cus">
            <em>
              {price_unit_01}<span class="js-lc--price">{price_value_01}</span>
              <small class="small_cus">
               {l_parenthesis}<span class="js-lc--basePrice">{price_value}</span>{price_unit}{r_parenthesis}
              </small>
            </em>
            </p>          
          </div>
          
          <div class="productMainSection__info original_price_div">
          <p class="productMainSection__price">
            <em>
              {price_unit_01}<span class="js-lc--price-01">{original_price_value_01}</span>
              <small>
               {l_parenthesis}<span class="js-lc--basePrice-01">{original_price_value}</span>{price_unit}{r_parenthesis}
              </small>
            </em>
          </p>
          </div>
        </div>`,
      modalBody: `
        <div class="modal_body">
          <div class="default_image">
           <img src="{image}" alt="">
          </div>
          <div class="product_detail">
            {product_info}
            <dl class="l-cartinSku">{sku}</dl>
            <div class="l-cartinQuantity">          
              {quantity}
              <div class="cartIn-btn">          
                <div class="btns">
                  <a href="https://shopee.tw/Sato_Brewery" class="cartin">{add_to_cart}</a>
                </div>
              </div>
              </div>
              <p class="detail_text"><a href="{link}">{detail}</a><p>
            </p></div>
            <dl class="l-cartinOption">{additions}</dl>
          </div>
        </div>
        `
    }

    

    const setCartInModule = (productId: number, $cartIn: JQuery<HTMLElement>, isDetailPage?: boolean) => {
      const cartInFormAction = `${ LC_PATH.cartin }${ productId }`;
      const $productMainSection = $cartIn.closest('.productMainSection');
      $cartIn.attr('action', cartInFormAction);
      $cartIn.html(template.loading);
      $.ajax({
        url: `${ LC_PATH.product }${ productId }/?api=1`,
        // url: `/moch/product.json`,
        dataType: 'text'
      })
      .done(response => {
        const responseData: ResponseData<Product> = typeof response === 'string' ? JSON.parse(response) : response;
        if (responseData.status === 'ok') {
          
          let defaultImage= responseData.results.defaultImage;
          let link= responseData.results.link;
          let detail= responseData.results.translatedText.detail;
          let htmlString = template.modalBody;
          htmlString = htmlString.replace(/\{image\}/g, defaultImage); 
          htmlString = htmlString.replace(/\{link\}/g, link);      
          if (isDetailPage) {
            htmlString = htmlString.replace(/\{product_info\}/g, '');
          } else {
            var originalPrice =  responseData.results.productSkus[0].price.valueskuOriginal.toLocaleString();            
            var curRatio = responseData.results.currencyRatio;
            if ( curRatio == 1){
              $('body').find('.productMainSection__price .js-lc--price').remove();
              $('body').find('.productMainSection__price .js-lc--price-01').remove();
              if (originalPrice == ''){
                htmlString = htmlString.replace(/\{product_info\}/g, template.product_info.replace(/\{product_name\}/g, responseData.results.name).replace(/\{price_value\}/g, responseData.results.productSkus[0].price.value.toLocaleString()).replace(/\{original_price_value\}/g, responseData.results.productSkus[0].price.valueskuOriginal.toLocaleString()).replace(/\{price_unit_01\}/g, "").replace(/\{price_unit\}/g, responseData.results.productSkus[0].price.unit).replace(/\{l_parenthesis\}/g, "").replace(/\{r_parenthesis\}/g, "").replace(/class="productMainSection__info original_price_div"/, 'class="productMainSection__info original_price_div" style="display: none;"').replace(/class="small_cus"/, 'class="small_cus" style="font-size: 2.4rem;margin-left: 0;"'));
              }
              else{
                htmlString = htmlString.replace(/\{product_info\}/g, template.product_info.replace(/\{product_name\}/g, responseData.results.name).replace(/\{price_value\}/g, responseData.results.productSkus[0].price.value.toLocaleString()).replace(/\{original_price_value\}/g, responseData.results.productSkus[0].price.valueskuOriginal.toLocaleString()).replace(/\{price_unit_01\}/g, "").replace(/\{price_unit\}/g, responseData.results.productSkus[0].price.unit).replace(/\{l_parenthesis\}/g, "").replace(/\{r_parenthesis\}/g, "").replace(/class="productMainSection__price color_cus"/, 'class="productMainSection__price color_cus" style="color: red;"').replace(/class="small_cus"/, 'class="small_cus" style="font-size: 2.4rem;margin-left: 0;color: red;"'));
              }   
            }
            else{              
              if (originalPrice == ''){
                htmlString = htmlString.replace(/\{product_info\}/g, template.product_info.replace(/\{product_name\}/g, responseData.results.name).replace(/\{price_unit_01\}/g, responseData.results.productSkus[0].price.unit.toString()).replace(/\{price_unit\}/g, responseData.results.productSkus[0].price.otherUnit.toString()).replace(/\{price_value\}/g, responseData.results.productSkus[0].price.value.toLocaleString()).replace(/\{original_price_value\}/g, responseData.results.productSkus[0].price.valueskuOriginal.toLocaleString()).replace(/\{l_parenthesis\}/g, "(").replace(/\{r_parenthesis\}/g, ")").replace(/class="productMainSection__info original_price_div"/, 'class="productMainSection__info original_price_div" style="display: none;"'));
              }
              else{
                htmlString = htmlString.replace(/\{product_info\}/g, template.product_info.replace(/\{product_name\}/g, responseData.results.name).replace(/\{price_unit_01\}/g, responseData.results.productSkus[0].price.unit.toString()).replace(/\{price_unit\}/g, responseData.results.productSkus[0].price.otherUnit.toString()).replace(/\{price_value\}/g, responseData.results.productSkus[0].price.value.toLocaleString()).replace(/\{original_price_value\}/g, responseData.results.productSkus[0].price.valueskuOriginal.toLocaleString()).replace(/\{l_parenthesis\}/g, "(").replace(/\{r_parenthesis\}/g, ")").replace(/class="productMainSection__price color_cus"/, 'class="productMainSection__price color_cus" style="color: red;"'));
              }
            }
          }

          // SKU選択
          if (responseData.results.productSkus[0].sku1 || responseData.results.productSkus[0].sku2) {
            let sku1HtmlString = '';
            let sku2HtmlString = '';

            // SKU1
            if (responseData.results.productSkus[0].sku1) {
              sku1HtmlString = template.skuSection;
              const sku1Title = responseData.results.translatedText.select.replace('%s', responseData.results.productSkus[0].sku1.title);
              
              const sku1ItemsHtmlString = responseData.results.productSkus[0].attribute?.image ? remakeSkuItemsWithImages(responseData.results.productSkus, 'sku1', responseData.results.attribute) : remakeSkuItemsWithoutImages(responseData.results.productSkus, 'sku1');
              sku1HtmlString = sku1HtmlString.replace(/\{title\}/g, sku1Title).replace(/\{items\}/g, sku1ItemsHtmlString);     
            }

            // SKU2
            if (responseData.results.productSkus[0].sku2) {
              sku2HtmlString = template.skuSection;
              const sku2Title = responseData.results.translatedText.select.replace('%s', responseData.results.productSkus[0].sku2.title);
              const sku2ItemsHtmlString = remakeSkuItemsWithoutImages(responseData.results.productSkus, 'sku2');
              sku2HtmlString = sku2HtmlString.replace(/\{title\}/g, sku2Title).replace(/\{items\}/g, sku2ItemsHtmlString);
            }

            htmlString = htmlString.replace(/\{sku\}/g, sku1HtmlString + sku2HtmlString);
          } else {
            htmlString = htmlString.replace(/\{sku\}/g, '');
            htmlString = htmlString.replace(/\{product_sku_id\}/g, String(responseData.results.productSkus[0].id));
          }

          // オプション商品選択
          if (responseData.results.additions.length) {
            const additions = reconstructAdditions(responseData.results.additions);
            let additionsHtmlString = getAdditionItemHtml(additions,  Object.keys(additions));
            htmlString = htmlString.replace(/\{additions\}/g, additionsHtmlString);
          } else {
            htmlString = htmlString.replace(/\{additions\}/g, '');
          }

          htmlString = htmlString.replace(/\{product_id\}/g, String(productId));
          htmlString = htmlString.replace(/\{favorite_class\}/g, responseData.results.favorites.indexOf(productId) > -1 ? ' is-active' : '');

          const availabeleProductSkus = responseData.results.productSkus.filter(productSku => productSku.stock || productSku.stockUnlimited);

          

          if (availabeleProductSkus.length) {
            // 数量の有効化           
            // htmlString = htmlString.replace(/\{quantity\}/g, template.quantity);
            var regular_interval_label= responseData.results.translatedText.regular_interval_label;
            var regularIntervalOptions = '';
            var regularIntervalValue = '';
              

              if (responseData.results.regularInterval && responseData.results.regularInterval.length > 0) {
                responseData.results.regularInterval.forEach(function(interval) {
                  regularIntervalOptions += `<option value="${interval.value}">${interval.label}</option>`;
                });

                regularIntervalValue = responseData.results.regularInterval[0].value.toString();
                htmlString = htmlString.replace(/\{quantity\}/g, template.quantity)
                .replace(/\{regularIntervalOptions\}/g, regularIntervalOptions)
                .replace(/\{regular_interval_label\}/g, regular_interval_label)
                .replace(/\{regularIntervalValue\}/g, `<input type="hidden" name="regular_int" value="${regularIntervalValue}">`);                

              }
              else{
                htmlString = htmlString.replace(/\{quantity\}/g, template.quantity)
                .replace(/\{regularIntervalOptions\}/g, '')
                .replace(/\{regularIntervalValue\}/g, '');
                htmlString = htmlString.replace(/class="selectBox selectBox_01"/, 'class="selectBox selectBox_01" style="display: none;"');
              }       
    
          } else {
            // 売り切れの時の処理
            htmlString = htmlString.replace(/\{quantity\}/g, '');
            htmlString = htmlString.replace(/\{add_to_cart\}/g, '完售');
          }

          // 翻訳の適用
          htmlString = htmlString.replace(/\{(.+?)\}/g, (match, p1) => {
            for (const key in responseData.results.translatedText) {
              if (key === p1) {
                return responseData.results.translatedText[key]
              }
            }
            return match;
          });
          
          $cartIn.html(htmlString);
          const $cartInButton = $cartIn.find('.productActoins__cartin');
          const $productSkuIdInput = $cartIn.find('[name="product_sku_id"]');
          let $CartInButtonPage: JQuery<HTMLElement>;
          if (responseData.results.productSkus.length === 1) {
            $cartInButton.prop('disabled', false);
          }
          if ($productMainSection.length) {
            $CartInButtonPage = $productMainSection.find('.productActoins__cartin--page');
            if (responseData.results.productSkus.length === 1) {
              $CartInButtonPage.prop('disabled', false);
            }
          }
          

          /**
           * イベントハンドラの設定
           */

          // Ajaxによるカートイン
          setCartInWithAjax($cartIn, $CartInButtonPage);

          // SKUの選択
          const $sku1Radios = $cartIn.find('[name="sku1"]');
          const $sku2Radios = $cartIn.find('[name="sku2"]');
          const onChangeSkuRadios = function () {
            let isEnableCartIn = false;
            const checkedSku1Name = $sku1Radios.length ? $sku1Radios.filter(':checked').attr('data-name') : '';
            const checkedSku2Name = $sku2Radios.length ? $sku2Radios.filter(':checked').attr('data-name') : '';
            const selectedSkuItem = responseData.results.productSkus.length > 1 ? responseData.results.productSkus.find(productSku => {
              if (productSku.sku1 && productSku.sku2) {
                return productSku.sku1.name === checkedSku1Name && productSku.sku2.name === checkedSku2Name;
              } else if (productSku.sku1) {
                return productSku.sku1.name === checkedSku1Name;
              } else if (productSku.sku2) {
                return productSku.sku2.name === checkedSku2Name;
              }
              return false
            }) : responseData.results.productSkus[0];
            if (selectedSkuItem) {
              isEnableCartIn = true;
            }
            if (isEnableCartIn) {
              $productSkuIdInput.val(selectedSkuItem.id);
              if ($productMainSection.length) {
                $productMainSection.find('.js-lc--basePrice').text(selectedSkuItem.price.value.toLocaleString());
+               $productMainSection.find('.js-lc--price').text(Math.floor(selectedSkuItem.price.value * responseData.results.currencyRatio).toLocaleString());
                $productMainSection.find('.js-lc--basePrice-01').text(selectedSkuItem.price.valueskuOriginal.toLocaleString());
+               $productMainSection.find('.js-lc--price-01').text(Math.floor(selectedSkuItem.price.valueskuOriginal * responseData.results.currencyRatio).toLocaleString());

              } else {
                var curRatio = responseData.results.currencyRatio;
                if ( curRatio == 1){
                  $cartIn.find('.js-lc--basePrice').text(selectedSkuItem.price.value.toLocaleString());
  +               $cartIn.find('.js-lc--price').remove();
                  $cartIn.find('.js-lc--basePrice-01').text(selectedSkuItem.price.valueskuOriginal.toLocaleString());
  +               $cartIn.find('.js-lc--price-01').remove();
                }
                else{
                  $cartIn.find('.js-lc--basePrice').text(selectedSkuItem.price.value.toLocaleString());
  +               $cartIn.find('.js-lc--price').text(Math.floor(selectedSkuItem.price.value * responseData.results.currencyRatio).toLocaleString());
                  $cartIn.find('.js-lc--basePrice-01').text(selectedSkuItem.price.valueskuOriginal.toLocaleString());
      +           $cartIn.find('.js-lc--price-01').text(Math.floor(selectedSkuItem.price.valueskuOriginal * responseData.results.currencyRatio).toLocaleString());   
                }                
              }
              $cartInButton.prop('disabled', false);
              if ($CartInButtonPage) {
                $CartInButtonPage.prop('disabled', false);
              }
            } else {
              $productSkuIdInput.val('');
              $cartInButton.prop('disabled', true);
              if ($CartInButtonPage) {
                $CartInButtonPage.prop('disabled', true);
              }
            }
            confirmSkuSelectionStatus(responseData.results.productSkus, $cartIn);
            setSelectedSkuQuantityMax(responseData.results.productSkus, $cartIn);
          }
          confirmSkuSelectionStatus(responseData.results.productSkus, $cartIn);
          setSelectedSkuQuantityMax(responseData.results.productSkus, $cartIn);
          $sku1Radios.on('change', onChangeSkuRadios);
          $sku2Radios.on('change', onChangeSkuRadios);
          if (availabeleProductSkus.length >= 1 ) {
            onChangeSkuRadios();
            if (availabeleProductSkus[0].sku1) {
              $cartIn.find(`[name="sku1"][data-name="${ availabeleProductSkus[0].sku1.name }"]`).prop('checked', true).trigger('change');
            }
            if (availabeleProductSkus[0].sku2) {
              $cartIn.find(`[name="sku2"][data-name="${ availabeleProductSkus[0].sku2.name }"]`).prop('checked', true).trigger('change');
            }
          }

          // オプション商品選択
          $cartIn.find('.l-cartinOption__select').on('change', function () {
            const $select = $(this);
            if ($select.find(':selected').attr('data-id')) {
              $select.attr('name', `addition_quantity[${ $select.find(':selected').attr('data-id')}]`);
              $select.find('option').val($cartIn.find('[name="quantity"]').val());
            } else {
              $select.attr('name', '');
              $select.find('option').val(0);
            }
          });
        }
      })
      .fail(function (_jqXHR, textStatus, errorThrown) {
        console.error(textStatus + ': ' + errorThrown);
      });
    }
    
    /**
     * オプション商品データをキーごとに整理し直して返す
     */
    const reconstructAdditions = (additions: ProductAddition[]): ReconstructedProductAdditions => {
      const reconstructedProductAdditions: ReconstructedProductAdditions = {};
      additions.forEach(addition => {
        const [additionLabel, additionOptionName] = addition.name.split(':');
        const newOption = {
          id: addition.id,
          name: additionOptionName,
          price: addition.price
        };
        if (additionLabel in reconstructedProductAdditions) {
          reconstructedProductAdditions[additionLabel].options.push(newOption);
        } else {
          reconstructedProductAdditions[additionLabel] = {
            title: additionLabel,
            options: [newOption]
          };
        }
      });
      return reconstructedProductAdditions;
    }

    /**
     * オプション商品のHTMLを取得
     * @param additions オプション商品の一覧
     * @param keys オプション商品の対象となるキー（title:name の title 部分）
     * @param itemClass オプションに設定する class属性名
     * @returns オプション商品のHTML
     */
    const getAdditionItemHtml = (additions: ReconstructedProductAdditions, keys: string[], itemClass?: string): string => {
      let additionsHtmlString = '';
      keys.forEach(key => {
        let additionHtmlString = template.optionItem;
        let optionsHtmlString = '';
        additions[key].options.forEach(option => {
          optionsHtmlString += `<option value="1" data-id="${ option.id }">${ option.name }${ option.price.value ? ' / ' + option.price.value + option.price.unit : '' }</option>`;
        });
        additionHtmlString = additionHtmlString.replace(/\{item_class\}/g, itemClass ? itemClass : '');
        additionHtmlString = additionHtmlString.replace(/\{name\}/g, key);
        additionHtmlString = additionHtmlString.replace(/\{options\}/g, optionsHtmlString);
        additionHtmlString = additionHtmlString.replace(/\{note\}/g, '');
        additionsHtmlString += additionHtmlString;
      });
      return additionsHtmlString;
    }

    /**
     * 画像つきのSKU選択一覧の作成
     * @param productSkus 商品SKU一覧
     * @param attribute 商品の拡張フィールド一覧
     * @returns SKU選択一覧のHTML
     */
    const remakeSkuItemsWithImages = (productSkus: ProductSku[], skuKey: 'sku1'|'sku2', attribute: { [k: string]: string; }): string => {

      let skuWithImagesHtmlString = '';
      let skuItemWithImagesHtmlString = template.skuItemWithImage;
      let nameList: string[] = [];
      let imageList: string[] = [];
      let skuItemList: {
        name: string;
        image: string;
      }[] = [];

      productSkus.forEach(productSku => {
        const name = productSku[skuKey].name;
        const image = productSku.attribute.image;
        if (nameList.indexOf(name) == -1 || imageList.indexOf(image) == -1) {
          skuItemList.push({name: name, image: image});
          nameList.push(name);
          imageList.push(image);
        }
      });

      skuItemList.forEach(skuItem => {
        if (attribute) {
          for (let key of Object.keys(attribute)) {
            if (key == skuItem.image) {
              skuItemWithImagesHtmlString = skuItemWithImagesHtmlString.replace(/\{image\}/g, attribute[key]);
              skuItemWithImagesHtmlString = skuItemWithImagesHtmlString.replace(/\{name\}/g, skuItem.name);
              skuWithImagesHtmlString += skuItemWithImagesHtmlString;
              skuItemWithImagesHtmlString = template.skuItemWithImage;
            }
          }
        }
      });
      skuWithImagesHtmlString = skuWithImagesHtmlString.replace(/\{sku_key\}/g, skuKey);

      return skuWithImagesHtmlString;
    }

    /**
     * 画像なしのSKU選択一覧の作成
     * @param productSkus 商品SKU一覧
     * @returns SKU選択一覧のHTML
     */
    const remakeSkuItemsWithoutImages = (productSkus: ProductSku[], skuKey: 'sku1'|'sku2'): string => {

      let skuWithoutImagesHtmlString = '';
      let skuWithoutImagesItemHtmlString = template.skuItemWithoutImage;
      let nameList: string[] = [];

      productSkus.forEach(productSku => {
        const name = productSku[skuKey].name;
        if (nameList.indexOf(name) == -1) {
          nameList.push(name);
        }
      });

      for (let item of nameList) {
        skuWithoutImagesItemHtmlString = skuWithoutImagesItemHtmlString.replace(/\{name\}/g, item);
        skuWithoutImagesHtmlString += skuWithoutImagesItemHtmlString;
        skuWithoutImagesItemHtmlString = template.skuItemWithoutImage;
      }
      skuWithoutImagesHtmlString = skuWithoutImagesHtmlString.replace(/\{sku_key\}/g, skuKey);

      return skuWithoutImagesHtmlString;
    }

    /**
     * SKU選択一覧の選択可能状態をチェックして設定
     * @param productSkus 商品SKU一覧
     * @param $cartIn jQueryの .cartIn 要素
     */
    const confirmSkuSelectionStatus = (productSkus: ProductSku[], $cartIn: JQuery<HTMLElement>) => {
      const $sku1Radios = $cartIn.find('[name="sku1"]');
      const $sku2Radios = $cartIn.find('[name="sku2"]');
      $sku1Radios.prop('disabled', false);
      $sku2Radios.prop('disabled', false);
      $sku1Radios.each(function () {
        const $sku1Radio = $(this);
        const sku1SelectionName = $sku1Radio.attr('data-name');
        const isChecked = $sku1Radio.prop('checked');
        const currentSku1Skus = productSkus.filter(productSku => productSku.sku1.name === sku1SelectionName);
        const disabledSkus = currentSku1Skus.filter(currentSku1Sku => currentSku1Sku.stockUnlimited === false && currentSku1Sku.stock === 0);
        if (isChecked && $sku2Radios.length) {
          $sku2Radios.each(function () {
            const $sku2Radio = $(this);
            const sku2SelectionName = $sku2Radio.attr('data-name');
            const skuCombination = productSkus.find(productSku => productSku.sku1.name === sku1SelectionName && productSku.sku2.name === sku2SelectionName);
            const currentDisabledSkuCombination = disabledSkus.find(disabledSku => disabledSku.sku1.name === sku1SelectionName && disabledSku.sku2.name === sku2SelectionName);
            if (!skuCombination || currentDisabledSkuCombination) {
              $sku2Radio.prop('disabled', true);
            }
          });
        } else if (currentSku1Skus.length === disabledSkus.length) {
          $sku1Radio.prop('disabled', true);
        }
      });
      $sku2Radios.each(function () {
        const $sku2Radio = $(this);
        const sku2SelectionName = $sku2Radio.attr('data-name');
        const isChecked = $sku2Radio.prop('checked');
        const currentSku2Skus = productSkus.filter(productSku => productSku.sku2.name === sku2SelectionName);
        const disabledSkus = currentSku2Skus.filter(currentSku2Sku =>  currentSku2Sku.stockUnlimited === false && currentSku2Sku.stock === 0);
        if (isChecked && $sku1Radios.length) {
          $sku1Radios.each(function () {
            const $sku1Radio = $(this);
            const sku1SelectionName = $sku1Radio.attr('data-name');
            const skuCombination = productSkus.find(productSku => productSku.sku2.name === sku2SelectionName && productSku.sku1.name === sku1SelectionName);
            const currentDisabledSkuCombination = disabledSkus.find(disabledSku => disabledSku.sku2.name === sku2SelectionName && disabledSku.sku1.name === sku1SelectionName);
            if (!skuCombination || currentDisabledSkuCombination) {
              $sku1Radio.prop('disabled', true);
            }
          });
        } else if (currentSku2Skus.length === disabledSkus.length) {
          $sku2Radio.prop('disabled', true);
        }
      });
    }

    /**
     * 選択中のSKUのstockからquantityの最大値を設定
     * @param productSkus 商品SKU一覧
     * @param $cartIn jQueryの .cartIn 要素
     */
    const setSelectedSkuQuantityMax = (productSkus: ProductSku[], $cartIn: JQuery<HTMLElement>) => {
      const $checkedSku1Radio = $cartIn.find('[name="sku1"]:checked');
      const $checkedSku2Radio = $cartIn.find('[name="sku2"]:checked');
      const $quantityInput = $cartIn.find('[name="quantity"]');
      let selectedSku: ProductSku;
      if (productSkus.length === 1) {
        selectedSku = productSkus[0];
      } else {
        if (productSkus[0].sku1 && productSkus[0].sku2) {
          if ($checkedSku1Radio.length && $checkedSku2Radio.length) {
            selectedSku = productSkus.find(productSku => productSku.sku1.name === $checkedSku1Radio.attr('data-name') && productSku.sku2.name === $checkedSku2Radio.attr('data-name'));
          }
        } else if (productSkus[0].sku1) {
          if ($checkedSku1Radio.length) {
            selectedSku = productSkus.find(productSku => productSku.sku1.name === $checkedSku1Radio.attr('data-name'));
          }
        } else {
          if ($checkedSku2Radio.length) {
            selectedSku = productSkus.find(productSku => productSku.sku2.name === $checkedSku2Radio.attr('data-name'));
          }
        }
      }
      if (selectedSku) {
        if (selectedSku.stockUnlimited) {
          $quantityInput.removeAttr('max');
          const selectElements = document.querySelectorAll('.l-cartinQuantity__input');
          selectElements.forEach((selectElement) => {
            let optionsHTML = '';
            for (let i = 1; i <= 10; i++) {
              optionsHTML += `<option value="${i}">${i}</option>`;
            }
            selectElement.innerHTML = optionsHTML;
          });
        } else {
          $quantityInput.attr('max', selectedSku.stock);         
          
          const selectElement = document.querySelector('.l-cartinQuantity__input');

            let optionsHTML = '';
            if( selectedSku.stock > 10){
              for (let i = 1; i <= 10; i++) {
                optionsHTML += `<option value="${i}">${i}</option>`;
              }
              selectElement.innerHTML = optionsHTML;

            }
            else{
              for (let i = 1; i <= selectedSku.stock; i++) {
                optionsHTML += `<option value="${i}">${i}</option>`;
              }
              selectElement.innerHTML = optionsHTML;

            }            

          if ($quantityInput.val() > selectedSku.stock) {
            $quantityInput.val(selectedSku.stock);    

          }
        }
      }
    }

    const $cartInModal = $('#cartInModal');
    //@ts-ignore TS6133: 'isCanceledCloseModal' is declared but its value is never read.
    var isCanceledCloseModal = false;
    if ($triggersAddToCart.length && $cartInModal.length) {
      const $cartIn = $cartInModal.find('.l-cartin');

      var closeButtonDiv = $('<div>', {
        class: 'close-button-div'
      });
      
      var closeButton = $('<button>', {
        class: 'close-button',
        click: function() {
          $cartInModal.removeClass('is-active');
          $('body').removeClass('no-scroll');
        }
      });      
      
 
      $(document).on('click', '.js-addToCart', function () {
        const $triggerAddToCart = $(this);
        isCanceledCloseModal = true;
        $cartInModal.addClass('is-active');
        $('body').addClass('no-scroll');
        closeButtonDiv.append(closeButton);
        $cartInModal.children('.l-cartInModal__body').css({
          //top: `${ window.scrollY + 60 }px`
        }).append(closeButtonDiv);
        const productId = Number($triggerAddToCart.attr('data-id'));
        if (productId) {
          setCartInModule(productId, $cartIn);
        }
        const $triggerCloseCartInModal = $cartInModal.find('.js-closeCartInModal');
        $triggerCloseCartInModal.on('click', () => {
          let isCanceledCloseModal = false;
          $cartInModal.removeClass('is-active');
          $('body').removeClass('no-scroll');
          const timer = setTimeout(() => {
            clearTimeout(timer);
            if (!isCanceledCloseModal) {
              $cartIn.html('');
            }
          }, 0);
        });
  
      });
    }
    if ($('.l-cartin[data-id]').length) {
      $('.l-cartin[data-id]').each(function () {
        const $cartIn = $(this);
        const productId = Number($cartIn.attr('data-id'));
        setCartInModule(productId, $cartIn, true);
      });
    }

  });

  

  //==========================================================
  // Cart in with Fetch
  //==========================================================
  const setCartInWithAjax = ($cartIn: JQuery<HTMLElement>, $cartInButtonPage: JQuery<HTMLElement>) => {
    const $cartinButtonElements = $cartIn.find('.js-lc--cartin');
    const cartBadgeContainerElements = document.querySelectorAll('.js-lc--cartBadgeContainer');
    const cartBadgeElementHTML = '<span class="headerMenu__badge js-lc--cartBadge"></span>';
    function onClickCartinButtonElements (this: HTMLElement, e: JQuery.ClickEvent<HTMLElement>) {
      e.preventDefault();
      e.stopPropagation();
      const $cartinButtonElement = $(this);
      if (!$cartinButtonElement.hasClass('is-disabled')) {
        $cartinButtonElement.prop('disabled', true);
        $cartinButtonElement.addClass('is-disabled');
        if ($cartInButtonPage) {
          $cartInButtonPage.prop('disabled', true);
          $cartInButtonPage.addClass('is-disabled');
        }
        cartIn();
      }

      function cartIn () {
        $.ajax($cartIn.attr('action'), {
          method: 'POST',
          headers: {
            'X-Requested-With': 'XMLHttpRequest'
          },
          dataType: 'json',
          data: $cartIn.serialize()
        })
          .done(data => {
            if(data.status == 'ok') {
              cartBadgeContainerElements.forEach(cartBadgeContainerElement => {
                if (!cartBadgeContainerElement.querySelector('.js-lc--cartBadge')) {
                  cartBadgeContainerElement.insertAdjacentHTML('afterbegin', cartBadgeElementHTML);
                }
                (cartBadgeContainerElement.querySelector('.js-lc--cartBadge') as HTMLElement).innerText = data.cart_count;
              });

              $('#cartInModal').removeClass('is-active');
              $('#modalNotice .modal_notice').css('display','block');
              $('#modalNotice .js-lc--modalNotice--01').css('display','block');
              $('#modalNotice .js-lc--modalNotice').css('display','none'); 
              $('#modalNotice .lc-modal__buttons').css('display','none');              
              (window as any).openModalNotice('Cart-in success!');
            } else {
              cartBadgeContainerElements.forEach(cartBadgeContainerElement => {
                cartBadgeContainerElement.querySelector('.js-lc--cartBadge').remove();
              });
              console.error(data.message);
              (window as any).openModalNotice('Cart-in failed.');
            }
          })
          .fail((_jqXHR, textStatus, _errorThrown) => {
            console.error( "Request failed: " + textStatus );
            (window as any).openModalNotice('Cart-in failed.');
          })
          .always(() => {
            $cartinButtonElement.prop('disabled', false);
            $cartinButtonElement.removeClass('is-disabled');
            if ($cartInButtonPage) {
              $cartInButtonPage.prop('disabled', false);
              $cartInButtonPage.removeClass('is-disabled');
            }
          });
      }
    }
    if ($cartInButtonPage) {
      $cartInButtonPage.on('click', onClickCartinButtonElements);
    }
    $cartinButtonElements.on('click', onClickCartinButtonElements);
  }

}